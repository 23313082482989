.splash-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  
  .splash-screen h1 {
    font-size: 64px;
  }
  .spinner {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: visible;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  
  .dot {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin: 0.5rem;
    opacity: 0;
    animation: appear 1s linear infinite;
  }
  
  .dot-1 {
    background-color: #95fbd4;
    animation-delay: 0.2s;
  }
  
  .dot-2 {
    background-color: #58e2ad;
    animation-delay: 0.4s;
  }
  
  .dot-3 {
    background-color: #46e8aa;
    animation-delay: 0.6s;
  }
  
  .dot-4 {
    background-color: #36eba6;
    animation-delay: 0.8s;
  }
  
  .dot-5 {
    background-color: #00cf81;
    animation-delay: 1s;
  }
  
  @keyframes appear {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  